/* Product.css */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

a {
  text-decoration: none;
  color: white;
}
/* Main container */

/* Image container */
.product-image {
  text-align: center; /* Center the image */
}

.product-image img {
  max-width: 100%; /* Ensure the image doesn't exceed its container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Add border radius for rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

/* Pet details container */
.pet-details {
  background-color: #fff; /* White background */
  padding: 20px; /* Add padding */
  border-radius: 8px; /* Add border radius */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.pet-details h1 {
  font-size: 24px; /* Title font size */
  margin-bottom: 15px; /* Add spacing below the title */
}

.pet-details h6 {
  font-size: 16px; /* Details font size */
  margin-bottom: 10px; /* Add spacing below each detail */
}

/* Description container */
.product-description {
  background-color: #eff4f4; /* Light gray background */
  border-radius: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.description-title {
  font-size: 20px; /* Title font size */
  margin-bottom: 15px; /* Add spacing below the title */
}

.description-text {
  font-size: 16px; /* Description font size */
  line-height: 1.6; /* Increase line height for readability */
}
.img-detail {
  transform: translate(-130%, 30%);
}
.bg-image {
  filter: blur(2px) !important;
  padding: 1rem;
}

/* Sty
le.css */
.comment-section {
  margin-top: 20px;
}

.comment-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.comment-list {
  margin-bottom: 20px;
}

.comment {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}

.comment-input {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  padding: 10px;
}

.comment-submit {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.comment-submit:hover {
  background-color: #0056b3;
}

.abouttext {
  background-color: white;
  margin-top: 0;
  z-index: 999;
}
@media screen and (max-width: 991px) {
  .swiper {
    margin-top: 0px;
  }
}
.hero-banner {
  background-color: white;
}
.herotext {
  text-align: start;
  font-family: "Poppins" !important ;
}
.hero-text h3 {
  font-weight: 500;
}
.hero-text p {
  color: #838181 !important;
  font-weight: 300;
}
.btn-hero {
  background-color: #d9d9d9 !important;
  border-radius: 1rem;
  padding-left: 15px;
  padding-right: 15px;
  color: black;
  border: none;
}

/* .swiper img {
  filter: brightness(70%);
} */
.hero-text {
  justify-content: space-between;
}
.loginbtn {
  background-color: #ffffff !important;
  border-radius: 2rem !important;
  margin-left: 1rem;
  max-width: 100%;
}

.grey {
  background-color: #eeeff0;
  border-radius: 2rem;
}
.Featured {
  padding-top: 4rem;
  padding-bottom: 4rem;
  font-family: "Poppins" !important ;
}
.Featured h1 {
  font-family: "Poppins" !important ;
}
.btn-purple {
  background-color: #3f2644;
  width: 8rem;
}
.btn-outline-more {
  color: black;
  border: 1px solid #4e2d69;
  border-radius: 1rem;
  padding-left: 15px;
  padding-right: 15px;
}
.snapcolor {
  color: #e186f6;
}
.btn-more {
  font-size: 15px;
  background-color: #ae20ce;
  border-radius: 1rem;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  border: none;
}
.card-text {
  font-size: 14px;
  font-weight: 400;
  color: #929292;
}
@media screen and (max-width: 500px) {
  .btn-more {
    width: 100%;
  }
  .btn-hero {
    font-size: 19px;
    background-color: #d9d9d9 !important;
    border-radius: 1rem;
    padding-left: 15px;
    padding-right: 13px;
    color: black;
    border: none;
  }
  .card-text {
    font-size: 17px;
  }
  .card-text p {
    margin-bottom: 9px;
  }
}
.btn-more:hover {
  cursor: pointer !important;
  -webkit-transition-duration: 0.4s !important; /* Safari */
  transition-duration: 0.4s !important;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.all-pets {
  padding-top: 3rem;
  align-items: center;
  justify-content: center;
  font-family: "Poppins" !important ;
}

.filter {
  background-color: white;
  color: #424a82;
  margin-top: 3rem;
  font-family: "Poppins" !important ;
  border-radius: 2rem;
  padding: 2rem;
  text-align: start;
}
.Details {
  font-family: "Poppins" !important ;
}
.detail-card {
  text-align: center;
}

.Menucard {
  background: linear-gradient(to top, #4e2d69, #656ebb) !important;
  padding-bottom: 1px;
  border-radius: 2rem;
}

.Menucard img {
  width: auto;
  height: 50vh;
}

@media screen and (max-width: 500px) {
  .Menucard img {
    height: 30vh;
  }
}
.username {
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-input {
  display: block;
  width: 10rem;
  padding: 0.375rem 2rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.dropdown-menu {
  --bs-dropdown-min-width: none !important;
  --bs-dropdown-padding-x: none !important;
  --bs-dropdown-padding-y: 0.5rem;
}

.dropdown-item:hover {
  color: white;
  background-color: #4e2d69;
  border-radius: 0.8rem;
}
.herotext {
  font-size: 36px !important;
}
@media screen and (max-width: 767px) {
  .col-sms-10 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .col-sms-9 {
    flex: 0 0 auto;
    width: 100%;
  }
  .mockup img {
    max-width: 50%;
  }
}

@media screen and (max-width: 576px) {
  .col-sms-5 {
    flex: 0 0 auto;
    width: 100%;

    text-align: center;
  }
  .herotext {
    font-size: 23px !important;
    padding-left: 3rem;
    padding-right: 3rem;

    text-align: center;
  }
  .hero-text {
    text-align: center !important;
  }
  .hero-text p {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 991px) {
  .filter {
    padding: 1rem;
  }
}
/* .mock1 {
  background-color: rgb(255, 255, 255);
} */
.mockup {
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
}
.mockup video {
  width: 300px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 16px 0 rgba(0, 0, 0, 0.19);

  border-radius: 20px;
}
.mockup p {
  color: #b9b9b9 !important;
}
.everbanner {
  background-color: #732f74;
}
.everbanner h3 {
  font-family: "Poppins", sans-serif;
  color: white;
}
.everbanner p {
  font-family: "Poppins", sans-serif;
  color: white;
}
.purpletxt {
  color: #df82f5;
}
.card {
  border-radius: 2rem !important;
}

.card img {
  border-radius: 2rem;
}

.card-body p {
  font-size: 14px;
}
.dashboard {
  font-family: "Poppins" !important ;
}
.list-group-item.active {
  z-index: 2;
  background-color: #4e2d69 !important;
}
.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #4e2d69 !important;
  border-radius: 2rem;
}
.real {
  padding-bottom: 2rem;
}
.waves {
  position: relative;
  width: 390px; /* Adjust this value to the desired size of the circular area */
  height: 390px; /* Adjust this value to the desired size of the circular area */
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #6d6b6b;
  border-style: solid;
  background-image: linear-gradient(
    210deg,
    rgb(56, 164, 235),
    rgb(227, 179, 48)
  );
}
@media only screen and (max-width: 991px) {
  .waves {
    width: 250px; /* Adjust this value to the desired size of the circular area */
    height: 250px;
  }
}

.waves .wave {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 35%;
  width: 100%;
  background: url(../imag/wave.png);
  background-size: 50rem 10rem;
  animation: waves 3s linear infinite;
  background-repeat: repeat-x;
}

.waves .wave2 {
  animation-direction: reverse;
  animation-duration: 2s;
  opacity: 0.3;
}

.waves .wave3 {
  animation-duration: 2s;
  opacity: 0.5;
}

@keyframes waves {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 50rem;
  }
}

/* .float {
  animation: updown 6s linear infinite;
} */
.float {
  bottom: 2%;
  position: relative;
  width: 70%;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: unset !important;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-text {
  background-color: #2f2e2e;
  padding: 1rem;
  border-bottom-left-radius: 25px; /* Adjust the value as needed */
  border-bottom-right-radius: 25px;
}

.swiper-pagination {
  position: static !important;
}
.swiper-button-prev,
.swiper-button-next {
  font-size: 12px !important;
  height: 12px !important;
  width: unset !important;
  color: #000000;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 16px;
  padding: 1rem;
  padding-right: 1.2rem;
  padding-left: 1.2rem;

  background-color: #bdbcbc;
  border-radius: 50%;
}
.loading-container {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.poppins {
  font-family: "Poppins" !important ;
}
.image-section {
  background-image: url("./TempComponent/Rakshya_Bandann/RakshyaBandan/image/rakshyabg.png") !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.downbtn {
  background-color: #ffffff;
  height: 7vh;
  text-align: center;
}
.downbtn a {
  text-align: center;
  font-size: 25px;
  color: #6c4a16;
}
.chat {
  height: 90px !important;
}
