@import url("https://fonts.googleapis.com/css2?family=Paytone+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Salsa&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Salsa&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");
@font-face {
  font-family: neu;
  src: url(./NeueHaasDisplayMediu.ttf);
}

@font-face {
  font-family: neu;
  font-weight: 100;
  src: url(./NeueHaasDisplayLight.ttf);
}

@font-face {
  font-family: neu;
  font-weight: 200;
  src: url(./NeueHaasDisplayRoman.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: neu;
}

html,
body {
  height: 100%;
  width: 100%;
}

.main {
  /* background-color: #000; */
  position: relative;
  z-index: 10;
}

.page1 {
  background-color: black;
  padding: 0 2vw;
}

nav {
  padding: 2vw 0vw;
  width: 100%;
  /* background-color: red; */
  display: flex;
  align-items: center;
  z-index: 100;
  justify-content: space-between;
}
.name {
  text-transform: uppercase;
  font-family: "Josefin Sans", sans-serif;
  width: auto;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 5px #ff00ff, 0 0 0px #ff00ff, 0 0 0px #ff00ff,
      0 0 0px #ff00ff, 0 0 10px #ff00ff, 0 0 0px #ff00ff, 0 0 0px #ff00ff;
  }
  50% {
    text-shadow: 0 0 0px #ff00ff, 0 0 10px #ff00ff, 0 0 10px #ff00ff,
      0 0 10px #ff00ff, 0 0 10px #ff00ff, 0 0 30px #ff00ff, 0 0 20px #ff00ff;
  }
  100% {
    text-shadow: 0 0 5px #ff00ff, 0 0 0px #ff00ff, 0 0 5px #ff00ff,
      0 0 0px #ff00ff, 0 0 10px #ff00ff, 0 0 5px #ff00ff, 0 0 20px #ff00ff;
  }
}

.username {
  text-transform: uppercase;
  font-family: "Josefin Sans", sans-serif;
  font-size: 2rem; /* Adjust font size as needed */
  color: #fff;
  text-shadow: 0 0 5px #ff00ff;
  animation: glow 2s infinite alternate;
}

.capitalize {
  text-transform: capitalize;
}
.saharsha {
  max-width: 100%;
  border-radius: 1rem;
}

nav h3 {
  display: none;
}

.center {
  /* height: 65vh; */
  width: 100%;
  /* background-color: orange; */
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #0000003c;
  padding-bottom: 2.5vw;
}
.left {
  width: 100%;
}
.left h3 {
  font-family: "Josefin Sans", sans-serif;
  text-align: center;
  font-size: 1.8vw;
  color: #fe6db0;
  line-height: 2vw;
}

.center h1 {
  font-size: 10vw;
  text-align: right;
  color: sandybrown;
  line-height: 8vw;
}

.page1 video {
  position: relative;
  border-radius: 30px;
  margin-top: 4vw;
  width: 100%;
}

.hero_shape {
  position: absolute;
  width: 46vw;
  height: 36vw;
  right: 0;
  top: 65vh;
}

.page_2 {
  background-color: black;
  min-height: 100vh;
  width: 100%;
  padding: 8vw 0;
  position: relative;
}

.moving_text {
  overflow-x: auto;
  white-space: nowrap;
  color: #ff456e;
}

.moving_text::-webkit-scrollbar {
  display: none;
}

.con {
  white-space: nowrap;
  display: inline-block;
  animation-name: move;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.moving_text h1 {
  font-size: 9vw;
  /* background-color: lightblue; */
  display: inline-block;
}

.gola {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: inline-block;
  background-color: #fe320a;
  margin: 1vw 2vw;
}

@keyframes move {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.page2_bottom {
  width: 100%;
  color: #df82f5;
  background-color: black;
  padding: 4.5vw;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 9;
}

/* .page2_bottom h1 {
  font-size: 4vw;
  width: 60%;
  line-height: 4vw;
} */

@keyframes gooey {
  from {
    filter: blur(20px);
    transform: translate(10%, -10%) skew(0);
  }

  to {
    filter: blur(30px);
    transform: translate(-10%, 10%) skew(-12deg);
  }
}

#page3 {
  width: 100%;
  background-color: black;
  padding: 4vw 0;
}

.elem {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0 2vw;
}

.elem .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: -100%;
  transition: all ease 0.25s;
}

.elem:hover .overlay {
  top: 0;
}

#page4 {
  width: 100%;
  background-color: black;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 30%;
  padding: 0 2vw;
}
nav img {
  transition: all ease 0.2s;
  height: 9vh;
}
.page5 {
  height: 100vh;
  width: 100%;
  /* background-color: #EFEAE3; */
}

.footer {
  position: fixed;
  height: 105vh;
  background-color: #000;
  color: #fff;
  z-index: 9;
  bottom: 0;
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-end;
  flex-direction: column;
  padding: 1vw 3vw;
}

.footer h1 {
  font-size: 16vw;
}

.footer_div {
  height: 10vh;
  width: 100%;
  background-color: rgb(252, 2, 244) !important;
}

.footer_bottom {
  border-top: 1px solid #dadada;
  height: 10vh;
  width: 100%;
}
.cake img {
  height: 500px;
  padding-top: 2rem;
  text-align: center;
  max-width: 100% !important;
  /* height: 20px !important; */
}
.saharsha {
  max-width: 100% !important;
  height: 460px !important;
  border-radius: 1rem;
  object-fit: cover !important;
}
.page2_bottom h1 {
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
}

@media (max-width: 600px) {
  .cake img {
    height: 180px;
    padding-top: 2rem;
    text-align: center;
    max-width: 100% !important;
    /* height: 20px !important; */
  }
  .page1 {
    min-height: 100vh;
    width: 100vw;
    padding: 0 0vw;
  }
  .saharsha {
    max-width: 100%;
    height: 340px;
    border-radius: 1rem;
    object-fit: cover;
  }

  nav {
    padding: 8vw 5vw;
    background-color: black;
    /* padding: 0 5vw; */
  }

  #nav-part2 {
    display: none;
  }

  nav h3 {
    padding: 3vw 5vw;
    border: 1px solid #ababab;
    border-radius: 50px;
    font-size: 4vw;
    font-weight: 200;
    padding-left: 10vw;
  }

  .center {
    /* height: 50vh; */
    width: 100%;
    /* background-color: orange; */
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid #0000003c;
    padding: 7vw 5vw;
    padding-bottom: 10vw;
    flex-direction: column-reverse;
    position: relative;
    z-index: 9;
  }

  .left h3 {
    font-size: 5.5vw;
    padding: 2rem;
    line-height: 6vw;
  }

  .center h1 {
    font-size: 14vw;
    text-align: center;
    line-height: 15vw;
  }

  .page_2 {
    min-height: 100vh;
    width: 100%;
    background-color: black;
    padding: 8vw 0;
    position: relative;
  }

  .moving_text {
    overflow-x: auto;
    white-space: nowrap;
  }

  .moving_text::-webkit-scrollbar {
    display: none;
  }

  .con {
    white-space: nowrap;
    display: inline-block;
    animation-name: move;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .moving_text h1 {
    font-size: 9vw;
    /* background-color: lightblue; */
    display: inline-block;
  }

  .gola {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    background-color: #fe320a;
    /* margin: 2vw 2vw; */
  }

  .page2_bottom {
    width: 100%;
    /* background-color: aliceblue; */
    padding: 10vw 4vw;
    display: flex;
    /* align-items: flex-start; */
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    z-index: 9;
  }

  .page2_bottom h1 {
    font-size: 6vw;
    width: 100%;
    line-height: 9vw;
  }
}
@media (max-width: 300px) {
  .saharsha {
    height: 200px;
  }
}
.loader {
  height: 100%;
  width: 100%;
  background-color: #000;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 3s ease, visibility 3s ease;
  visibility: visible;
  opacity: 1;
}

.loader.hidden {
  visibility: hidden;
  opacity: 0;
}

.loader h1 {
  font-size: 7vw;
  text-align: center;
  font-family: "Great Vibes", cursive !important;
  color: transparent;
  background: linear-gradient(to right, #fe6db0, #ff456e);
  -webkit-background-clip: text;
  position: relative;
  opacity: 0;
  text-transform: capitalize;
  animation: loadText 4s ease forwards;
}

@keyframes loadText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .loader h1 {
    font-size: 13vw;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.col-sm-5 {
  width: 50% !important;
}
.col-sm-5 img {
  border-radius: 1rem;
}
.snapgreetlogo {
  padding: 6rem;
}
.button_wrapper {
  position: relative;
}

.button_wrapper canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.play_button {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  background-color: #2a172f;
  padding: 1rem;
  border: none;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.play_button:hover {
  transform: scale(1.05);
}

.play_button:active {
  background-color: white;
  color: black;
  transform: scale(1.1);
}

.logoimgfirst {
  text-align: center;
  height: 90px;
  width: 100px;
}
.glitter {
  height: unset;
  position: relative;
  transform: translate(0, 50px) !important;
}
.right {
  width: 100%;
  position: relative;
}
