.admin-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.notification-1 {
  background-color: #fff;
  border-left: 5px solid #007bff;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.notification-1 .content {
  flex-grow: 1;
}

.notification-1 .header {
  text-align: start;
  font-size: medium;
  font-weight: 500;
  margin-bottom: 5px;
}

.notification-1 .meta {
  font-size: medium;
  color: #888;
  margin-bottom: 10px;
}

.notification-1 .description {
  font-size: medium;
}

.approve-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.approve-button:hover {
  background-color: #0056b3;
}

.copy-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #4caf50; /* Green background */
  color: white; /* White text */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer/hand icon */
  font-size: 14px; /* Font size */
}

.copy-button:hover {
  background-color: #45a049; /* Darker green background on hover */
}
@media only screen and (max-width: 541px) {
  .notification-1 .header {
    font-size: 12px;
    margin-right: 7px;
  }
  .approve-button {
    font-size: 12px;
    padding: 5px 10px;
  }
  .copy-button {
    margin-top: 0.5rem !important;
    margin-left: 6px;

    font-size: 11px;
  }
}
