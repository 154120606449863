.reset-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .reset-password-form {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .reset-password-title {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .reset-password-label {
    margin-bottom: 5px;
    color: #333;
  }
  
  .reset-password-input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .reset-password-input:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .reset-password-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .reset-password-button:hover {
    background-color: #0056b3;
  }
  
  .reset-password-message {
    margin-top: 20px;
    color: red;
    font-weight: bold;
  }
  