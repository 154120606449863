@import url("https://fonts.googleapis.com/css2?family=Salsa&display=swap");

.nav_text:hover {
  color: black !important;
}
/* .nav_text:hover {
    font-size: 16px;
    color: #424a82 !important;
    font-weight: lighter;
  } */

.drop {
  width: 120px;
}
.Ham {
  height: 29px;
}
.logo {
  color: #ff4c0a !important;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-size: 40px !important;
}
.dropdownText {
  text-decoration: none;
  color: #111111;
}

.dropdownItem:hover .dropdownText {
  color: #9e2222;
}
.drop a:hover {
  background-color: white;
}

@media screen and (max-width: 991px) {
  .dropdownItem {
    text-align: center !important;
  }

  .dropdownItem:hover .dropdownText {
    color: #9e2222;
  }
  .drop {
    width: auto;
  }
  .logo {
    font-size: 35px !important;
  }
}

@media screen and (max-width: 600px) {
  .logo {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 600px) {
}

/* Add the following styles to your existing CSS */

/* Container for the entire navbar */
.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Styles for the logo */
.logoimg img {
  height: 40px;
  /* Push the logo to the left */
}

/* Styles for the navbar links */
.Navbar.Collapse {
  display: flex !important;
  justify-content: center !important;
}
.second-nav {
  justify-content: center !important;
  border-radius: 1rem;
  background: linear-gradient(to bottom, rgb(194, 126, 245), rgb(246, 92, 246));
}
.bg-purple {
  background-color: blueviolet !important;
}
/* Media query for smaller screens */
@media screen and (max-width: 991px) {
  .Container {
    flex-direction: column !important; /* Stack items vertically on smaller screens */
  }

  .logo {
    margin-bottom: 10px !important; /* Add some space between the logo and the links */
  }
}
.rightnav {
  column-gap: 3rem;
}
.rightnavicon {
  padding: 14px;
  border-radius: 50%;
  background-color: #f1f2f7;
}
.logoname {
  font-size: 35px;
  padding-top: 1rem;
  padding-left: 1rem;
  font-family: "Salsa", cursive;
}

.profile-icon {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  object-fit: cover;
}

/* Default icon color */
.nav-icon-1 svg {
  color: #000000;
  transition: color 0.3s;
}

/* Change the icon color when the link is active */
.nav-icon-active svg {
  color: #c23016 !important;
}

.nav-item p {
  margin: 0;
  font-weight: 500;
}
.nav-item-1 p {
  padding-left: 1rem;
}
.canvas-top a {
  width: 100%;
}
/* Other CSS remains the same */
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active .arrow {
  position: absolute;
  transform: translate(4px, 37px);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid white;
  /* z-index: 9999 !important; */
}

@media screen and (max-width: 769px) {
  .rightnav {
    column-gap: 2rem;
  }

  .logoname {
    font-size: 24px;
    padding-top: 0.7rem;
    padding-left: 0.5rem;
  }
  .logoimg img {
    height: 48px;
  }

  .profile-icon {
    width: 39px;
    height: 39px;
  }
}

.nav-item p {
  padding-top: 4px;
  font-size: 10.7px;
}
@media screen and (max-width: 575px) {
  .second-nav {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .nav-item p {
    font-size: 12px;
  }
}

@media screen and (max-width: 413px) {
  .Ham {
    height: 23px;
  }
  .nav-item p {
    font-size: 10px;
  }
  .rightnavicon {
    padding: 6px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .rightnav {
    column-gap: 1.2rem;
  }
}

@media screen and (max-width: 383px) {
  .logoname {
    font-size: 19px;
    padding-top: 0.2rem;
    padding-left: 0.5rem;
  }
  .nav-item p {
    padding-top: 2px;
    font-size: 7px;
  }
  .logoimg img {
    height: 47px;
  }
  .rightnav {
    column-gap: 2rem;
  }
  .rightnavicon {
    padding: 8px;
  }
  .rightnav {
    column-gap: 1.2rem;
  }
  .nav-tabs .nav-link {
    padding: 0.5rem !important;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active .arrow {
    transform: translate(4px, 23px);

    /* z-index: 9999 !important; */
  }
}

.box {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.nav-item-1 a {
  font-size: 16px !important;
}
.nav-item-1 svg {
  font-size: 20px !important;
}
.nav-item-1 {
  padding: 0.5rem;
}
.nav-item-1:hover {
  background: linear-gradient(to bottom, rgb(194, 126, 245), rgb(246, 92, 246));
}
/* .offcanvas-backdrop {
  background-color: unset !important;
} */
.nav-tabs {
  background-color: white;
}
.nav-tabs .nav-link {
  border-radius: 50%;
  padding: 1rem;

  background-color: rgb(243, 174, 242) !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-radius: 50%;
  padding: 1rem;
  background-color: white !important;
  border-color: #f265c7 !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 16px 0 rgba(0, 0, 0, 0.19);
}
