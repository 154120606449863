/* Add this CSS to your stylesheet, e.g., App.css */

@import url("https://fonts.googleapis.com/css2?family=Paytone+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: neu;
  src: url(./NeueHaasDisplayMediu.ttf);
}

@font-face {
  font-family: neu;
  font-weight: 100;
  src: url(./NeueHaasDisplayLight.ttf);
}

@font-face {
  font-family: neu;
  font-weight: 200;
  src: url(./NeueHaasDisplayRoman.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: neu;
}

html,
body {
  height: 100%;
  width: 100%;
}

.main {
  /* background-color: #000; */
  position: relative;
  z-index: 10;
}

.page1 {
  background-color: #fae7cc;
  padding: 0 2vw;
}

.nav {
  padding: 2vw 0vw;
  width: 100%;
  /* background-color: red; */
  display: flex;
  align-items: center;
  z-index: 100;
  justify-content: space-between;
}
.nav img {
  transition: all ease 0.2s;
  height: 9vh;
}

.name {
  width: auto;
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.saharsha {
  /* max-width: 100%; */
  padding: 0.7rem;
  padding-bottom: 4rem;
  margin-bottom: 2rem;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#nav-part2 {
  display: flex;
  align-items: center;
  gap: 1vw;
}

#nav-part2 h4 {
  padding: 10px 20px;
  border: 1px solid #0000003c;
  border-radius: 50px;
  font-weight: 500;
  color: #000000bb;
  transition: all ease 0.4s;
  position: relative;
  font-size: 18px;
  overflow: hidden;
}

#nav-part2 h4::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: black;
  left: 0;
  bottom: -100%;
  border-radius: 50%;
  transition: all ease 0.4s;
}

#nav-part2 h4:hover::after {
  bottom: 0;
  border-radius: 0;
}

#nav-part2 h4 a {
  color: #000000bb;
  text-decoration: none;
  position: relative;
  z-index: 9;
}

#nav-part2 h4:hover a {
  color: #fff;
}

nav h3 {
  display: none;
}

.center {
  width: 100%;
  /* background-color: orange; */
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #0000003c;
  padding-bottom: 2.5vw;
}
.left {
  margin-top: 2rem;
}
.left h3 {
  width: 25vw;
  font-size: 1.8vw;
  color: #fe6db0;
  line-height: 2vw;
}

.center h1 {
  font-size: 10vw;
  text-align: right;
  line-height: 8vw;
}

.page1 video {
  position: relative;
  border-radius: 30px;
  margin-top: 4vw;
  width: 100%;
}

#hero-shape {
  position: absolute;
  width: 46vw;
  height: 36vw;
  right: 0;
  top: 65vh;
}

#hero-1 {
  background-color: #fe320a;
  height: 100%;
  width: 100%;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  filter: blur(10px);
  position: absolute;
}

#hero-2 {
  background: linear-gradient(#fe320a, #fe3f0a);

  height: 30vw;
  width: 30vw;
  border-radius: 50%;
  position: absolute;
  animation-name: anime2;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  filter: blur(25px);
}

#hero-3 {
  background: linear-gradient(#fe320a, #fe3f0a);
  height: 30vw;
  position: absolute;
  width: 30vw;
  border-radius: 50%;
  filter: blur(25px);
  animation-name: anime1;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.play_button {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  background-color: #2a172f;
  padding: 1rem;
  border: none;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.play_button:hover {
  transform: scale(1.05);
}

.play_button:active {
  background-color: white;
  color: black;
  transform: scale(1.1);
}

@keyframes anime1 {
  from {
    transform: translate(55%, -3%);
  }

  to {
    transform: translate(0%, 10%);
  }
}

@keyframes anime2 {
  from {
    transform: translate(5%, -5%);
  }

  to {
    transform: translate(-20%, 30%);
  }
}

.page2 {
  min-height: 100vh;
  width: 100%;
  background-color: #fae7cc;
  position: relative;
  text-align: center;
}
.page2 img {
  height: 350px;
}
.moving_text {
  overflow-x: auto;
  white-space: nowrap;
  color: #ff456e;
}

.moving_text::-webkit-scrollbar {
  display: none;
}

.con {
  white-space: nowrap;
  display: inline-block;
  animation-name: move;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.moving_text h1 {
  font-size: 9vw;
  /* background-color: lightblue; */
  display: inline-block;
}

.gola {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: inline-block;
  background-color: #fe320a;
  margin: 1vw 2vw;
}
.hidden {
  display: none;
}

@keyframes move {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.page2_bottom {
  background-color: white;
  width: 100%;
  color: #df82f5;
  padding: 4.5vw;
  margin-top: 2rem;
  /* display: flex; */
  align-items: center;
  border-radius: 2rem;
  justify-content: space-between;
  position: relative;
  z-index: 9;
}

.page2_bottom h1 {
  font-size: 5vw;
  line-height: 6vw;
}

.page2_bottom img {
  text-align: start;
  height: 70px !important;
}
#bottom-part2 {
  width: 20%;
  /* background-color: aqua; */
}

#bottom-part2 img {
  width: 100%;
  border-radius: 15px;
}

#bottom-part2 p {
  font-weight: 200;
  margin-top: 2vw;
  font-size: 1vw;
}

@keyframes gooey {
  from {
    filter: blur(20px);
    transform: translate(10%, -10%) skew(0);
  }

  to {
    filter: blur(30px);
    transform: translate(-10%, 10%) skew(-12deg);
  }
}

#page3 {
  width: 100%;
  background-color: #fae7cc;
  padding: 4vw 0;
}

.elem {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0 2vw;
}

.elem .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: -100%;
  transition: all ease 0.25s;
}

.elem:hover .overlay {
  top: 0;
}

#fixed-image {
  height: 30vw;
  width: 24vw;
  /* background-color: red; */
  border-radius: 15px;
  position: fixed;
  z-index: 99;
  left: 50%;
  top: 25%;
  display: none;
  background-size: cover;
  background-position: center;
}

#page4 {
  width: 100%;
  background-color: white;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 30%;
  padding: 0 2vw;
}

.page5 {
  height: 100vh;
  width: 100%;
  /* background-color: #EFEAE3; */
}

.footer {
  width: 100%;
  position: fixed;
  height: 105vh;
  background-color: #000;
  color: #fff;
  z-index: 9;
  bottom: 0;
  display: flex;
  /* align-items: flex-end; */
  justify-content: flex-end;
  flex-direction: column;
  padding: 1vw 3vw;
}

.footer h1 {
  font-size: 16vw;
}

.footer_div {
  height: 10vh;
  width: 100%;
  background-color: rgb(252, 2, 244);
}

.footer_bottom {
  border-top: 1px solid #dadada;
  height: 10vh;
  width: 100%;
}

#full-scr {
  height: 100vh;
  width: 100%;
  background-color: #00000070;
  position: fixed;
  z-index: 99;
  top: -100%;
  transition: all ease 0.5s;
}

#full-div1 {
  height: 50%;
  width: 100%;
  background-color: #efeae3;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.cake {
  text-align: right;
}
.gift {
  background-color: white;
  overflow: hidden;
  text-align: center;
}
.saharsha {
  /* max-width: 100%; */
  height: 360px;
  width: 300px;
  object-fit: cover;
}
.flower1 img {
  height: 200px;
}
.flower2 img {
  height: 200px;
}
@media (max-width: 600px) {
  .page1 {
    min-height: 100vh;
    width: 100vw;
    padding: 0 0vw;
  }
  .flower1 img {
    max-width: 100% !important;
    height: 80px;
  }
  .flower2 img {
    max-width: 100% !important;
    height: 80px;
  }
  .nav {
    padding: 8vw 5vw;
    background-color: #fae7cc;
    /* padding: 0 5vw; */
  }

  .saharsha {
    max-width: 100% !important;

    height: 300px;
    width: 190px !important;
    object-fit: cover !important;
  }
  #nav-part2 {
    display: none;
  }

  .nav h3 {
    padding: 3vw 5vw;
    border: 1px solid #ababab;
    border-radius: 50px;
    font-size: 4vw;
    font-weight: 200;
    padding-left: 10vw;
  }

  .center {
    width: 100%;
    /* background-color: orange; */
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid #0000003c;
    padding: 7vw 5vw;
    padding-bottom: 10vw;
    flex-direction: column-reverse;
    position: relative;
    z-index: 9;
  }

  .left h3 {
    width: 80%;
    font-size: 5.5vw;
    line-height: 6vw;
  }

  .center h1 {
    font-size: 17vw;
    color: darkorchid;
    text-align: right;
    line-height: 15vw;
  }

  .page2 {
    min-height: 100vh;
    width: 100%;
    background-color: #fae7cc;
    position: relative;
  }

  .moving_text {
    overflow-x: auto;
    white-space: nowrap;
  }

  .moving_text::-webkit-scrollbar {
    display: none;
  }

  .con {
    margin-top: 2rem;
    margin-bottom: 2rem;
    white-space: nowrap;
    display: inline-block;
    animation-name: move;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .moving_text h1 {
    font-size: 8vw;
    /* background-color: lightblue; */
    display: inline-block;
  }

  .gola {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    background-color: #fe320a;
    margin: 2vw 2vw;
  }

  .page2_bottom {
    width: 100%;
    /* background-color: aliceblue; */
    padding: 10vw 4vw;
    /* display: flex; */
    /* align-items: flex-start; */
    justify-content: space-between;
    position: relative;
    flex-direction: column;
    z-index: 9;
  }

  .page2_bottom h1 {
    font-size: 8.2vw;
    width: 100%;
    line-height: 9vw;
  }

  #bottom-part2 {
    width: 70%;
    /* background-color: aqua; */
  }

  #bottom-part2 img {
    width: 100%;
    border-radius: 10px;
  }

  #bottom-part2 p {
    font-weight: 200;
    margin-top: 2vw;
    font-size: 3vw;
  }

  .cake img {
    height: 300px;
  }
  .gift img {
    height: 300px;
  }
}
.loader1 {
  background-color: #000;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoimgfirst {
  text-align: center;
  height: 90px;
  width: 100px;
}
.loader {
  height: 100%;
  width: 100%;
  background-color: #000;
  position: fixed;
  z-index: 999;
  top: 0;
  transition: all ease 0.7s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader h1 {
  font-size: 7vw;
  font-family: "Paytone One", sans-serif !important;
  color: transparent;
  background: linear-gradient(to right, #fe6db0, #ff456e);
  -webkit-background-clip: text;
  position: absolute;
  opacity: 0;
  animation-name: load;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-timing-function: linear;
}
.loader h1:nth-child(2) {
  animation-delay: 2s;
}
.loader h1:nth-child(3) {
  animation-delay: 3s;
}

@keyframes load {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 600px) {
  .loader h1 {
    font-size: 13vw;
  }
  .page2 img {
    height: auto;
  }
}

.col_sm_5 {
  width: 50% !important;
}

.snapgreetlogo {
  padding: 6rem;
}
.button_wrapper {
  position: relative;
}

.button_wrapper canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
@keyframes glow {
  0% {
    text-shadow: 0 0 5px #ff00ff, 0 0 0px #ff00ff, 0 0 0px #ff00ff,
      0 0 0px #ff00ff, 0 0 10px #ff00ff, 0 0 0px #ff00ff, 0 0 0px #ff00ff;
  }
  50% {
    text-shadow: 0 0 0px #ff00ff, 0 0 10px #ff00ff, 0 0 10px #ff00ff,
      0 0 10px #ff00ff, 0 0 10px #ff00ff, 0 0 30px #ff00ff, 0 0 20px #ff00ff;
  }
  100% {
    text-shadow: 0 0 5px #ff00ff, 0 0 0px #ff00ff, 0 0 5px #ff00ff,
      0 0 0px #ff00ff, 0 0 10px #ff00ff, 0 0 5px #ff00ff, 0 0 20px #ff00ff;
  }
}

.username {
  text-transform: uppercase;
  font-family: "Josefin Sans", sans-serif;
  font-size: 2rem; /* Adjust font size as needed */
  color: #fff;
  text-shadow: 0 0 5px #ff00ff;
  animation: glow 2s infinite alternate;
}

.flower1 {
  text-align: center;
}
