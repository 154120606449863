/* General Container Styles */
.find-template-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.find-template-container h1 {
  font-family: "Poppins" !important ;
}

/* Template Selector Wrapper */
.template-selector-wrapper {
  margin-top: 30px;
}

/* Template Selector */
.template-selector {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.template-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.template-option {
  margin: 15px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
}

.template-option:hover {
  transform: scale(1.05);
}

.template-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-top-left-radius: 25px; /* Adjust the value as needed */
  border-top-right-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.template-option button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.template-option button:hover {
  background-color: #0056b3;
}

/* Template Detail */
.template-detail {
  background-color: #ffffff;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  text-align: center;
}

.template-detail h2 {
  font-size: 2em;
  color: #333;
}

.template-image-container {
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.template-image-container img {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.template-detail h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #555;
}

.template-detail p {
  font-size: 1em;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
}

.template-detail button {
  padding: 12px 25px;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.template-detail button:hover {
  background-color: #215b88;
}

/* TemplateForm.css */

.template-form {
  max-width: 600px;
  margin: 40px auto;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.template-form h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.template-form .error {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  margin-bottom: 20px;
}

.template-form input[type="text"],
.template-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.template-form textarea {
  resize: vertical;
  height: 100px;
}

.template-form input[type="file"] {
  margin-bottom: 20px;
}

.template-form button {
  background-color: #200779;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.template-form button:hover {
  background-color: #124e8f;
}

.template-form button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .template-form {
    margin: 20px;
    padding: 15px;
  }
}

.photo-previews {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.photo-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ddd;
  border-radius: 4px;
}
