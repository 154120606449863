/* src/components/QRCode.css */

.card {
  max-width: 500px;
}

.card img {
  max-width: 200px;
  height: auto;
}

.card-subtitle {
  color: red; /* Change the h6 subtitle to red */
}

.random-chars {
  color: blue; /* Change the color of randomChars text */
  font-weight: bold; /* Make the randomChars text bold */
}
.note p {
  font-weight: 600;
}
.note {
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #d9d9d9;
}
