@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Reem+Kufi+Fun&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@200&family=Jost:ital,wght@0,200;0,300;0,400;0,600;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Salsa&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  height: unset !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Ensures the background image stays fixed as the content scrolls */
}

.nav-link {
  color: rgb(0, 0, 0) !important;
}
.dark-font {
  color: #777777 !important;
  font-size: 17px;
}

.purple {
  color: #9b00bf;
}
.navbar-toggler-icon {
  background-image: url(./imag/menu.png) !important;
}
.navbar {
  background-color: white !important;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-size: 17px;
  line-height: 26px;
  font-weight: 100 !important;
  z-index: 99;
}

.navbar-brand {
  font-family: "roboto", sans-serif;
  letter-spacing: normal;
}

/* =============================
==========footer============= */
.footer {
  font-family: "Poppins" !important ;
  /* color: white; */
  padding: 25px;
  background-color: #732f74;

  border-radius: 3rem 3rem 0 0; /* top-left, top-right, bottom-right, bottom-left */
}
.footer h3 {
  font-family: "Poppins" !important ;
}
.footer p {
  font-family: "Poppins" !important ;
  font-weight: 400;
}
.footer a {
  text-decoration: none;
  color: white;
  padding: 10px;
}
.footer a:hover {
  color: rgb(165, 169, 139);
}
.footer-form input::placeholder {
  font-weight: 200;
  color: white;
}
.footer-form textarea::placeholder {
  color: white;
  font-weight: 200;
}

/* =================================
======== page not found css ======= */
.pnf {
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pnf-title {
  font-size: 100px;
  font-weight: 700;
}

.pnf-heading {
  font-weight: normal;
}

.pnf-btn {
  color: black;
  border: 1px solid black;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}
.pnf-btn:hover {
  background-color: black;
  color: white;
}

/* ========================================= */
/* =========contact us ========= */
.contactus {
  margin: 0;
  padding: 0;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ========================================= */
/* =========Register ========= */
.loginpage {
  column-gap: rem;
}
.loading-gif {
  height: 40px !important;
}
.register {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  justify-content: center;
  column-gap: 10rem;
}
.register p {
  margin: 0 !important;
}
.slogan {
  font-family: "Salsa", cursive;
  font-size: 19px;
}
.love {
  color: #ff496f;
}
.register img {
  margin-top: 4rem;
  margin-bottom: 2rem;

  height: 160px;
}
@media only screen and (max-width: 768px) {
  .register {
    width: unset;

    position: static !important;
    padding: 3rem;
  }
  .register img {
    margin-top: 1rem;

    margin-bottom: 2rem;
    height: 90px !important;
  }
}

/* ========================================= */
/* =========User Info ========= */
/* UserInfo.css */

.userInfo-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.userInfo-heading {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.userInfo-info {
  margin-bottom: 10px;
}

.userInfo-label {
  font-weight: bold;
}

.userInfo-value {
  margin-left: 10px;
}

/* ==================== */
.product-link {
  text-decoration: none !important;
  color: black !important;
}

.card-image {
  height: auto !important;
}

.filter-bar {
  background-color: #ffffff;
}
li {
  text-decoration: none !important;
}
.nav-item {
  font-weight: 100 !important;
  color: #3f2644 !important;
}
.nav-item a {
  width: 100%;
}
.wallpaper-image-2 {
  z-index: -1;
}
.wallpaper-image img {
  width: 100%;
  height: 550px;
}
/* @media only screen and (max-width: 963px) {
  .wallpaper-image img {
    height: auto;
  }
} */
.orange-box {
  height: 50%;
  width: 6%;
  background-color: orange;
  position: absolute;
  top: 50%;
  left: 61%;
  transform: translate(10%, -50%);
}
/* @media only screen and (max-width: 972px) {
  .orange-box {
    left: 5%;
  }
} */
.wallpaper-text {
  color: white;
  font-size: 40px;
  position: absolute;

  font-weight: 700;
  transform: translate(10%, -50%);
  z-index: 3;
}
/* @media only screen and (max-width: 972px) {
  .wallpaper-text {
    left: 10%;
  }
}
@media only screen and (max-width: 720px) {
  .wallpaper-text {
    font-size: 30px;
  }
} */
.wallpaper-image-2 img {
  width: 100%;
  height: 350px;
  margin-top: -20px;
  filter: brightness(70%);
}

.wallpaper-text-2 {
  color: white;
  text-align: center;
}
@media only screen and (max-width: 1595px) {
  .wallpaper-image-2 img {
    height: auto;
  }
}
.wallpaper-text-2 p {
  margin-left: 20%;
  margin-right: 20%;
  text-align: center;
}
@media only screen and (max-width: 1595px) {
  .wallpaper-2 {
    flex-direction: column;
  }
}
@media only screen and (max-width: 1595px) {
  .wallpaper-text-2 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.full-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.card-home {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.wallpaper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .col-md-4 {
    flex: 0 0 auto;
    width: 20%;
  }
}
.register h1 {
  font-family: "Nunito Sans", sans-serif !important;
}

/* ========================================= */
/* =========Comment-Reply ========= */
.comment-list {
  margin: 20px 0; /* Add some space above and below the comment list */
}

.comment {
  padding: 10px; /* Add padding for a clear visual structure */
  border-bottom: 1px solid #ddd; /* Separate comments with a border */
}

.reply-list {
  padding-left: 20px; /* Indent replies for visual distinction */
  border-left: 2px solid #ddd; /* Add a vertical line to separate replies */
  margin-top: 10px; /* Space between the comment and its replies */
}

.reply {
  padding: 5px 10px; /* Add padding to replies */
  background-color: #f9f9f9; /* Use a light background for replies */
}

.reply-button {
  margin-left: 10px; /* Space between the comment and the reply button */
  color: #007bff; /* Blue text for the reply button */
  background: none;
  border: none;
  cursor: pointer;
}

.reply-input {
  width: 80%; /* Control the width of the input box */
  margin-top: 10px; /* Space above the input box */
}

.reply-submit {
  margin-top: 10px; /* Space between the input box and the submit button */
  color: white;
  background-color: #007bff; /* Blue background for the submit button */
  border: none;
  padding: 5px 10px; /* Add padding to the submit button */
  cursor: pointer;
}

/* ========================================= */
/* =========Adoption-Pending ========= */
.adoption-table {
  width: 95%;
  border-collapse: collapse;
}

.adoption-table th,
.adoption-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.adoption-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.adoption-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.adoption-table tbody tr:hover {
  background-color: #ddd;
}

/* ========================================= */
/* =========Product-Details (delete/edit button) ========= */
.comment {
  margin-bottom: 20px;
}

.comment-author,
.reply-author {
  font-weight: bold;
}

.comment-actions,
.reply-actions {
  margin-top: 5px;
}

.comment-actions button,
.reply-actions button {
  margin-right: 5px;
}

.comment-text,
.reply-text {
  margin-top: 5px;
  padding: 5px;
  background-color: #f2f2f2;
}

.reply {
  margin-left: 20px;
}

.reply-input {
  margin-bottom: 5px;
  width: 100%;
}

.reply-submit {
  margin-left: 5px;
}

.delete-comment,
.delete-reply,
.edit-comment,
.edit-reply {
  background-color: #ff6347;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.delete-comment:hover,
.delete-reply:hover,
.edit-comment:hover,
.edit-reply:hover {
  background-color: #d6342d;
}
.input {
  width: 100% !important;
  border-radius: 6px;
  border: unset !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  height: 50px !important;
  margin: 9px 0px 10px;
  padding-left: 2.3em;
}
.input-icons {
  color: #f45b98;
  position: absolute;
  top: 50;
  left: 0;
  transform: translate(70%, 105%);
}
.link {
  color: black;
}

/* Adoption form */
.popup-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
}

.password-toggle-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-9%);
  cursor: pointer;
}
.button-wrapper {
  position: relative;
}

.button-wrapper canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
