.admin-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.notification {
  background-color: #fff;
  border-left: 5px solid #007bff;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* .content {
  display: flex;
  align-items: center;
  flex-grow: 1;
} */
.user-photo {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  object-fit: cover;
}

.user-details {
  flex-grow: 1;
}

.user-details .header {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
}

.user-details .meta {
  font-size: 1em;
  color: #888;
  margin-bottom: 10px;
}

.approve-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.approve-button:hover {
  background-color: #0056b3;
}

.reject-button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
}

.reject-button:hover {
  background-color: darkred;
}
